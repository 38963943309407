// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/blog/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/blog/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-2019-06-08-monzo-nicked-my-idea-index-mdx": () => import("/opt/build/repo/blog/src/pages/2019-06-08-monzo-nicked-my-idea/index.mdx" /* webpackChunkName: "component---src-pages-2019-06-08-monzo-nicked-my-idea-index-mdx" */),
  "component---src-pages-2019-07-04-agile-is-thai-street-food-index-mdx": () => import("/opt/build/repo/blog/src/pages/2019-07-04-agile-is-thai-street-food/index.mdx" /* webpackChunkName: "component---src-pages-2019-07-04-agile-is-thai-street-food-index-mdx" */)
}

